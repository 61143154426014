<template>
  <el-dialog v-if="dataList" :title="title" @closed="_closed" :visible.sync="visible" :close-on-click-modal="false" :close-on-press-escape="false" custom-class="top5" top="5vh" width="80%">
    <l-table :option="tableOption" :data="dataList">
      <template slot="importState" slot-scope="scope">
        <span v-if="scope.row.importState == -1" class="text-grey">{{ scope.row.importDesc }}</span>
        <span v-else-if="scope.row.importState == 0" class="text-blue">等待导入</span>
        <span v-else-if="scope.row.importState == 9" class="text-red">{{ scope.row.importDesc }}</span>
        <span v-else-if="scope.row.importState == 10" class="text-green">导入成功</span>
      </template>
    </l-table>
    <div slot="footer" class="dialog-footer">
      <div class="fl" v-if="stateInfo">
        <span class="margin-right">导入进度: {{ stateInfo.finish }}/{{ stateInfo.total }}</span>
        成功：<span class="text-green padding-lr-sm">{{ stateInfo.success }}</span> 失败：<span class="text-red padding-lr-sm">{{ stateInfo.fail }}</span> 忽略<span class="text-grey padding-lr-sm">{{
          stateInfo.ignore
        }}</span>
      </div>
      <el-button @click="_cancle">取 消</el-button>
      <el-button type="primary" @click="doImport">开始导入</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      title: '', // 内置参数,误删
      visible: true, // 内置参数,误删
      dataList: null,
      stateInfo: null,
      tableOption: {
        stripe: true,
        height: document.documentElement.clientHeight - 280 + 'px',
        columns: [
          { label: '序号', type: 'index', fixed: true },
          { label: '导入状态', slot: 'importState', fixed: 'right' },
          { label: '资产编号', prop: 'assetCode', fixed: true, overflow: true },
          { label: '资产名称', prop: 'assetName', fixed: true },
          { label: '分类编号', prop: 'categoryCode', fixed: true, width: 100 },
          { label: '分类名称', prop: 'categoryName', fixed: true },
          { label: '区域编号', prop: 'areaCode', fixed: true },
          { label: '区域名称', prop: 'areaName', fixed: true, width: 120 },
          { label: '管理部门', prop: 'departName', fixed: true, width: 100 },
          { label: '管理人', prop: 'userName', fixed: true },
          { label: '使用部门', prop: 'useDepartName', width: 100 },
          { label: '使用人', prop: 'useUserName' },
          { label: '是否固定资产', prop: 'isFixed', filter: a => (a ? '是' : '否') },
          { label: '是否盘点', prop: 'isChecked', filter: a => (a ? '是' : '否') },
          { label: '是否可借', prop: 'isBorrowed', filter: a => (a ? '是' : '否') },
          { label: '是否定期检查', prop: 'isInspected', filter: a => (a ? '是' : '否') },
          { label: '是否定期检定', prop: 'isTest', filter: a => (a ? '是' : '否') },
          { label: '卡片编号', prop: 'cardCode' },
          { label: '品牌', prop: 'brandName' },
          { label: '规格型号', prop: 'modelName' },
          { label: '计量单位', prop: 'unit' },
          { label: '本币原值', prop: 'originalValue' },
          { label: '制造商', prop: 'factory' },
          { label: '采购时间', prop: 'purchaseDate' },
          { label: '精度等级', prop: 'accuracyClass' },
          { label: '使用月限', prop: 'monthLimit' },
          { label: '保修期', prop: 'period' },
          { label: '出厂编号', prop: 'factoryNum' },
          { label: '国标分类', prop: 'nationalCode' },
          { label: '国标名称', prop: 'nationalName' }
        ]
      }
    }
  },
  methods: {
    _closed() {
      // 底层内置方法 误删
      this.onclosed()
    },
    _cancle() {
      // 底层内置方法 误删
      this.visible = false
      this.cancle && this.cancle()
    },
    doImport() {
      this.$lgh.msgConfirm('确认开始导入明细?', () => {
        this.stateInfo = {
          total: this.dataList.length,
          finish: 0,
          ignore: 0,
          fail: 0,
          success: 0
        }
        this.importItem(0, this.dataList[0])
      })
    },
    importItem(index, data) {
      var isLast = false
      if (index === this.stateInfo.total - 1) isLast = true
      this.$lgh.post('api/enterprise/easset/import/item?isLast=' + isLast, Object.assign({}, data)).then(res => {
        data.importState = res.importState
        data.importDesc = res.importDesc
        this.stateInfo.finish++
        if (res.importState === -1) this.stateInfo.ignore++
        else if (res.importState === 9) this.stateInfo.fail++
        else if (res.importState === 10) this.stateInfo.success++
        if (isLast) {
          this.$lgh.msgConfirm('导入完成，关闭导入窗口?', () => {
            this.visible = false
            this.onsuccess && this.onsuccess(this.dataList)
          })
        } else {
          this.importItem(index + 1, this.dataList[index + 1])
        }
      })
    }
  }
}
</script>

<style></style>
