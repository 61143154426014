<template>
  <el-select
    v-model="mData"
    value-key="userId"
    filterable
    remote
    reserve-keyword
    :placeholder="placeholder"
    :remote-method="handleSearch"
    :loading="loading"
    @change="handleChange"
    @focus="handleFocus"
  >
    <el-option v-for="item in dataList" :key="item.employeeId" :label="item.name" :value="item"> </el-option>
  </el-select>
</template>

<script>
export default {
  name: 'LSelectEmployee',
  props: {
    modelVal: {},
    code: {},
    departId: {},
    placeholder: {
      type: String,
      default: '请输入姓名|手机号'
    }
  },
  model: {
    prop: 'modelVal',
    event: 'input'
  },
  data() {
    return {
      mData: null,
      loading: false,
      dataList: []
    }
  },
  mounted() {
    this.mData = this.modelVal || ''
  },
  methods: {
    handleSearch(query) {
      if (!this.departId) {
        this.dataList = []
        this.$lgh.toastError('请选择部门')
        return
      }
      if (query !== '') {
        this.loading = true
        this.$lgh
          .get(
            'api/enterprise/employee/search',
            [
              'enterpriseCode',
              {
                keyword: query,
                departId: this.departId
              }
            ],
            false
          )
          .then(res => {
            this.loading = false
            this.dataList = res
          })
      } else {
        this.dataList = []
      }
    },
    handleChange(item) {
      this.mData = item.name
      this.$emit('input', this.mData)
      this.$emit('change', item, this.code)
    },
    handleFocus() {
      if (!this.departId) return
      this.handleSearch()
    }
  },
  watch: {
    modelVal(value) {
      this.mData = this.modelVal || ''
    }
  }
}
</script>
