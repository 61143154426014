<template>
  <section class="l-content">
    <el-card name="hTable">
      <el-form :inline="true" :model="condition">
        <el-form-item label="资产分类">
          <el-cascader v-model="codeArr" :props="categoryProps" :options="categoryList" clearable placeholder="---不限---" @change="getPageData"></el-cascader>
        </el-form-item>
        <el-form-item label="资产状态">
          <l-select v-model="condition.assetState" :data="$constant.easset_state_list" @change="getPageData"></l-select>
        </el-form-item>
        <el-form-item label="使用状态">
          <l-select v-model="condition.useState" :data="$constant.easset_usestate_list" @change="getPageData"></l-select>
        </el-form-item>
        <el-form-item>
          <el-input v-model="condition.keyword" placeholder="资产编号|资产名称"> </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getPageData()">查询</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-divider> </el-divider>
    <el-card v-if="pageData">
      <l-table-top :option="tableOption">
        <div slot="left">
          <!-- <el-button type="primary">设置管理人</el-button> -->
          <!-- <el-button type="success">条码打印</el-button> -->
          <l-upload @success="uploadSuccess" :url="importUrl">
            <el-button icon="el-icon-upload">批量导入资产</el-button>
          </l-upload>
          <el-link class="margin-lr-sm" type="primary" target="_blank" :href="$site.URL_API + 'template/导入-资产批量导入模板.xlsx'">模板下载</el-link>
          <!-- <el-button type="info">导出资产</el-button> -->
        </div>
        <template>
          <el-divider> </el-divider>
          <l-table :option="tableOption" :data="pageData.list" :start-index="pageData.startIndex" height="400">
            <template slot="assetName" slot-scope="scope">
              <el-button type="text" @click="$site.view_easset_detail(scope.row.assetId)">{{ scope.row.assetName }}</el-button>
            </template>

            <template slot="assetState" slot-scope="scope">
              <span v-if="scope.row.assetState == 1">在建</span>
              <span v-else-if="scope.row.assetState == 2">启用</span>
              <span v-else-if="scope.row.assetState == 3">封存</span>
              <span v-else-if="scope.row.assetState == 4">处置</span>
              <span v-else-if="scope.row.assetState == 5">报废</span>
            </template>
            <template slot="reponsibleUserName" slot-scope="scope">
              <span>{{ scope.row.reponsibleUserName }}</span>
              <el-button type="warning" class="fr" @click="setReponsibleUser(scope.row)" icon="el-icon-edit" circle size="mini"></el-button>
            </template>
            <template slot="useUserName" slot-scope="scope">
              <span>{{ scope.row.useUserName }}</span>
              <el-button type="warning" class="fr" @click="setUseUser(scope.row)" icon="el-icon-edit" circle size="mini"></el-button>
            </template>
          </l-table>
          <l-pager :page="pageData" @change="getPageData"></l-pager>
        </template>
      </l-table-top>
      <!-- <l-table :option="tableOption" :data="pageData.list" :start-index="pageData.startIndex">
            <template slot="assetName" slot-scope="scope">
              <el-button type="text" @click="$site.view_easset_detail(scope.row.assetId)">{{ scope.row.assetName }}</el-button>
            </template>

            <template slot="assetState" slot-scope="scope">
              <span v-if="scope.row.assetState == 1">在建</span>
              <span v-else-if="scope.row.assetState == 2">启用</span>
              <span v-else-if="scope.row.assetState == 3">封存</span>
              <span v-else-if="scope.row.assetState == 4">处置</span>
              <span v-else-if="scope.row.assetState == 5">报废</span>
            </template>
            <template slot="reponsibleUserName" slot-scope="scope">
              <span>{{ scope.row.reponsibleUserName }}</span>
              <el-button type="warning" class="fr" @click="setReponsibleUser(scope.row)" icon="el-icon-edit" circle size="mini"></el-button>
            </template>
            <template slot="useUserName" slot-scope="scope">
              <span>{{ scope.row.useUserName }}</span>
              <el-button type="warning" class="fr" @click="setUseUser(scope.row)" icon="el-icon-edit" circle size="mini"></el-button>
            </template>
          </l-table> -->

    </el-card>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import assetAddoredit from './_addoredit'
import assetImport from './_import'
export default {
  computed: {
    ...mapState(['userInfo', 'dictData'])
  },
  name: 'AssetList',
  data() {
    return {
      tableOption: {
        stripe: false,
        columns: [
          { label: '序号', type: 'index' },
          { label: '资产编号', prop: 'assetCode' },
          { label: '资产名称', slot: 'assetName' },
          { label: '资产分类', prop: 'categoryFullName' },
          { label: '品牌', prop: 'brandName' },
          { label: '规格型号', prop: 'modelName' },
          { label: '计量单位', prop: 'unit', selected: false },
          { label: '购置金额', prop: 'price', selected: false },
          { label: '保修期', prop: 'period', selected: false },
          { label: '厂家', prop: 'factory', selected: false },
          { label: '购置日期', prop: 'purchaseDate', selected: false },
          { label: '购置方式', prop: 'purchaseTypeName', selected: false },
          { label: '精度等级', prop: 'accuracyClass', selected: false },
          { label: '使用月限', prop: 'monthLimit', selected: false },
          { label: '本币原值', prop: 'originalValue', selected: false },
          { label: '出厂编号', prop: 'factoryNum', selected: false },
          { label: '检定周期', prop: 'testPeriod', selected: false },
          { label: '检定日期', prop: 'testDate', selected: false },
          { label: '检定单位', prop: 'testUnit', selected: false },
          { label: '委外检定证件编号', prop: 'certificateNo', selected: false },
          { label: '区域编号', prop: 'areaCode' },
          { label: '管理部门', prop: 'departName' },
          { label: '管理人', prop: 'userName' },
          { label: '使用部门', prop: 'useDepartName', selected: false },
          { label: '使用人', prop: 'useUserName', selected: false },
          { label: '是否固定资产', prop: 'isFixed', filter: (a) => (a ? '是' : '否') },
          { label: '是否盘点', prop: 'isChecked', filter: (a) => (a ? '是' : '否') },
          { label: '是否定期检查', prop: 'isInspected', filter: (a) => (a ? '是' : '否') },
          { label: '是否可借', prop: 'isBorrowed', filter: (a) => (a ? '是' : '否') },
          { label: '资产状态', slot: 'assetState', filter: (a) => (a ? '是' : '否') }
        ],
        operation: {
          width: '160',
          header: { type: 'warning', icon: 'el-icon-plus', label: '新增资产', click: this.add },
          buttons: [{ type: 'primary', icon: 'el-icon-edit', label: '编辑', click: this.edit }]
        }
      },
      importUrl: 'api/enterprise/easset/import',
      codeArr: [],
      condition: {
        // enterpriseCode: this.userInfo.enterpriseCode,
        keyword: '',
        categoryCode: '',
        assetState: '',
        useState: '',
        pageIndex: 1,
        pageSize: 10
      },
      pageData: null,
      categoryList: null,
      categoryProps: {
        checkStrictly: true,
        expandTrigger: 'hover',
        value: 'categoryCode',
        label: 'categoryName'
      }
    }
  },
  created() {
    this.importUrl += '?enterpriseCode=' + this.userInfo.enterpriseCode
    this.getCategoryList()
    this.getPageData()
  },
  methods: {
    getCategoryList() {
      this.$lgh.get('api/enterprise/easset/category/tree', ['enterpriseCode'], false).then((res) => {
        this.categoryList = res
      })
    },
    async getPageData(pageOption) {
      if (this.codeArr.length !== 0) {
        this.condition.categoryCode = this.codeArr[this.codeArr.length - 1]
      } else {
        this.condition.categoryCode = ''
      }
      Object.assign(this.condition, pageOption)
      this.pageData = await this.$lgh.get('api/enterprise/easset/list', ['enterpriseCode', this.condition])
    },
    async add() {
      var data = await this.$lgh.get('api/enterprise/easset/default')
      await this.$lgh.openDialog({
        ins: assetAddoredit,
        title: '新增资产',
        data: {
          dataInfo: Object.assign(data, {
            enterpriseCode: this.userInfo.enterpriseCode
          })
        }
      })
      this.$lgh.toastSuccess('新建资产信息成功')

      this.getPageData()
    },
    async edit(item) {
      await this.$lgh.openDialog({
        ins: assetAddoredit,
        title: '修改资产信息',
        data: {
          dataInfo: Object.assign({}, item)
        }
      })
      this.$lgh.toastSuccess('修改资产信息成功')
      this.getPageData()
    },
    setReponsibleUser(item) {
      this.$site.sel_enterpriseEmployee(this.userInfo.enterpriseCode, { userId: item.reponsibleUserId }).then((user) => {
        this.$lgh
          .get('api/enterprise/asset/set/reponsibleuser', {
            assetId: item.assetId,
            userId: user.userId,
            recordUserId: this.userInfo.userId,
            recordUserName: this.userInfo.fullName
          })
          .then((e) => {
            this.getPageData()
          })
      })
    },
    setUseUser(item) {
      this.$site.sel_enterpriseEmployee(this.userInfo.enterpriseCode, { userId: item.useUserId }).then((user) => {
        this.$lgh
          .get('api/enterprise/asset/set/useuser', {
            assetId: item.assetId,
            userId: user.userId,
            recordUserId: this.userInfo.userId,
            recordUserName: this.userInfo.fullName
          })
          .then((e) => {
            this.getPageData()
          })
      })
    },
    async uploadSuccess(data) {
      data.forEach((a) => {
        a.enterpriseCode = this.userInfo.enterpriseCode
      })
      await this.$lgh.openDialog({
        ins: assetImport,
        title: '资产批量导入',
        data: {
          dataList: data
        }
      })
      this.getPageData()
    }
  }
}
</script>
