<template>
  <el-dialog v-if="dataInfo" width="80%" :title="title" @closed="_closed" :visible.sync="visible" :close-on-click-modal="false" :close-on-press-escape="false" custom-class="top5" top="5vh">
    <el-form ref="dataInfo" :model="dataInfo" :rules="dataRules" label-width="120px">
      <!-- <el-divider class="margin-top">资产信息</el-divider> -->
      <el-row class="treble padding" style="padding-bottom:0">
        <el-form-item label="资产编号" prop="assetCode">
          <el-input v-model="dataInfo.assetCode" autocomplete="off" style="width:205px;" placeholder="手动输入"></el-input>
        </el-form-item>
        <el-form-item label="资产名称" prop="assetName">
          <el-input v-model="dataInfo.assetName" autocomplete="off" style="width:205px;" placeholder="请输入资产名称"></el-input>
        </el-form-item>
        <el-form-item label="资产分类" prop="categoryCodeArr">
          <el-cascader v-model="dataInfo.categoryCodeArr" :props="categoryProps" :options="categoryList"></el-cascader>
        </el-form-item>
        <el-form-item label="资产状态" prop="assetState">
          <l-select v-model="dataInfo.assetState" :data="$constant.easset_state_list" :all="false" all-label="--请选择--"></l-select>
        </el-form-item>
        <el-form-item label="固定资产" prop="isFixed">
          <el-switch v-model="dataInfo.isFixed"> </el-switch>
        </el-form-item>
        <el-form-item label="国标分类" prop="nationalCode">
          <el-input v-model="dataInfo.nationalCode" autocomplete="off" style="width:205px;" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="管理部门" prop="departId">
          <l-select v-model="dataInfo.departId" :remote="$api.departList" :all="false" all-label="--请选择--" @change="departChange"></l-select>
        </el-form-item>
        <el-form-item label="管理人" prop="userId">
          <l-select-employee v-model="dataInfo.userName" :departId="dataInfo.departId" code="user" @change="userChange"></l-select-employee>
        </el-form-item>
        <el-form-item label="区域编号" prop="areaCode">
          <el-input v-model="dataInfo.areaCode" autocomplete="off" style="width:205px;" placeholder="资产所在区域编号"></el-input>
        </el-form-item>

        <el-form-item label="是否盘点" prop="isChecked">
          <el-switch v-model="dataInfo.isChecked"> </el-switch>
        </el-form-item>
        <el-form-item label="定期检查" prop="isInspected">
          <el-switch v-model="dataInfo.isInspected"> </el-switch>
        </el-form-item>
        <el-form-item label="是否可借" prop="isBorrowed">
          <el-switch v-model="dataInfo.isBorrowed"> </el-switch>
        </el-form-item>

        <el-form-item label="使用部门" prop="useDepartId">
          <l-select v-model="dataInfo.useDepartId" :remote="$api.departList" :all="false" all-label="--请选择--" @change="departChange2"></l-select>
        </el-form-item>
        <el-form-item label="使用人" prop="useUserId">
          <l-select-employee v-model="dataInfo.useUserName" :departId="dataInfo.useDepartId" code="useUser" @change="userChange"></l-select-employee>
        </el-form-item>
      </el-row>
      <!-- <el-divider>其他信息</el-divider> -->
      <el-divider></el-divider>
      <el-row class="treble padding">
        <el-form-item label="卡片编号" prop="cardCode">
          <el-input v-model="dataInfo.cardCode" autocomplete="off" style="width:205px;"></el-input>
        </el-form-item>
        <el-form-item label="资产品牌" prop="brandName">
          <el-input v-model="dataInfo.brandName" autocomplete="off" style="width:205px;" placeholder="请输入资产品牌"></el-input>
        </el-form-item>
        <el-form-item label="规格型号" prop="modelName">
          <el-input v-model="dataInfo.modelName" autocomplete="off" style="width:205px;" placeholder="请输入规格型号"></el-input>
        </el-form-item>
        <el-form-item label="厂家" prop="factory">
          <el-input v-model="dataInfo.factory" autocomplete="off" style="width:205px;"></el-input>
        </el-form-item>
        <el-form-item label="出厂编号" prop="factoryNum">
          <el-input v-model="dataInfo.factoryNum" autocomplete="off" style="width:205px;"></el-input>
        </el-form-item>

        <el-form-item label="保修期" prop="period">
          <el-input v-model="dataInfo.period" autocomplete="off" style="width:205px;"></el-input>
        </el-form-item>
        <el-form-item label="精度等级" prop="accuracyClass">
          <el-input v-model="dataInfo.accuracyClass" autocomplete="off" style="width:205px;"></el-input>
        </el-form-item>
        <el-form-item label="购置日期" prop="purchaseDate">
          <l-datepicker type="date" v-model="dataInfo.purchaseDate" style="width:205px;"></l-datepicker>
        </el-form-item>
        <el-form-item label="购置方式" prop="purchaseType">
          <l-select v-model="dataInfo.purchaseType" :data="$constant.easset_purchaseType_list" :all="false" all-label="--请选择--"></l-select>
        </el-form-item>
        <el-form-item label="购置金额" prop="price">
          <el-input v-model="dataInfo.price" autocomplete="off" style="width:150px;">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item label="本币原值" prop="originalValue">
          <el-input v-model="dataInfo.originalValue" autocomplete="off" style="width:205px;"></el-input>
        </el-form-item>
        <el-form-item label="计量单位" prop="unit">
          <l-select v-model="dataInfo.unit" :data="$constant.easset_unit_list" :all="false" all-label="--请选择--"></l-select>
        </el-form-item>
        <el-form-item label="使用月限" prop="monthLimit">
          <el-input v-model="dataInfo.monthLimit" autocomplete="off" style="width:150px;">
            <template slot="append">个月</template>
          </el-input>
        </el-form-item>
        <el-form-item label="检定周期" prop="testPeriod">
          <el-input v-model="dataInfo.testPeriod" autocomplete="off" style="width:205px;"></el-input>
        </el-form-item>
        <el-form-item label="检定日期" prop="testDate">
          <el-input v-model="dataInfo.testDate" autocomplete="off" style="width:205px;"></el-input>
        </el-form-item>
        <el-form-item label="检定单位" prop="testUnit">
          <el-input v-model="dataInfo.testUnit" autocomplete="off" style="width:205px;"></el-input>
        </el-form-item>
        <el-form-item label="检定编号" prop="certificateNo">
          <el-input v-model="dataInfo.certificateNo" autocomplete="off" style="width:205px;"></el-input>
        </el-form-item>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="_cancle">取 消</el-button>
      <el-button type="primary" @click="save">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import lSelectEmployee from '@/views/components/l-select-employee.vue'
export default {
  components: {
    'l-select-employee': lSelectEmployee
  },
  data() {
    return {
      title: '', // 内置参数,误删
      visible: true, // 内置参数,误删
      dataInfo: null,
      eSeachLoading: false,
      eSeachList: [],
      categoryList: null,
      categoryProps: {
        expandTrigger: 'hover',
        value: 'categoryCode',
        label: 'categoryName'
      },
      dataRules: {
        assetCode: [{ required: true, trigger: 'blur', message: '资产编号不能为空' }],
        assetName: [{ required: true, trigger: 'blur', message: '资产名称不能为空' }],
        categoryCodeArr: [{ required: true, trigger: 'blur', message: '资产分类不能为空' }],
        assetState: [{ required: true, trigger: 'blur', message: '资产状态不能为空' }],
        // purchaseType: [{ required: true, trigger: 'blur', message: '购置方式不能为空' }],
        userId: [{ required: true, trigger: 'blur', message: '管理员不能为空' }]
      }
    }
  },
  mounted() {
    this.getCategoryList()
  },
  methods: {
    _closed() {
      // 底层内置方法 误删
      this.onclosed()
    },
    _cancle() {
      // 底层内置方法 误删
      this.visible = false
      this.cancle && this.cancle()
    },
    getCategoryList() {
      this.$lgh.get('api/enterprise/easset/category/tree', ['enterpriseCode'], false).then(res => {
        this.categoryList = res
      })
    },
    uploaded(res) {
      Object.assign(this.dataInfo, {
        imgUrlList: res
      })
      // this.$set(this.dataInfo)
    },
    departChange() {
      this.dataInfo.userId = null
      this.dataInfo.userName = null
    },
    departChange2() {
      this.dataInfo.useUserId = null
      this.dataInfo.useUserName = null
    },
    userChange(item, code) {
      this.dataInfo[code + 'Id'] = item.userId
      this.dataInfo[code + 'Name'] = item.name
    },
    save() {
      this.$refs.dataInfo.validate(async valid => {
        if (valid) {
          await this.$lgh.post('api/enterprise/easset/addoredit', this.dataInfo)
          this.visible = false
          this.onsuccess && this.onsuccess(this.dataInfo)
        }
      })
    }
  }
}
</script>

<style></style>
